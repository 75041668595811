html {
  overflow-x: hidden;
}

body {
  background-color: rgb(20,80,10);
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0), rgba(255,255,255,0.2), rgba(255,255,255,0), rgba(255,255,255,0)),
                    linear-gradient(to bottom,rgb(0,0,37), rgb(0,30,110) 49.8%, rgb(65,80,35) 50%, rgb(50,70,20) 65%, rgb(55,75,20) 85%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  pointer-events: none;
}

.App {
  text-align: center;
  pointer-events: none;
}

.App > * {
  pointer-events: auto;
}

.AppLogo {
  height: 3em;
  animation: AppLogoSpin infinite 5s linear;
}

.AppHeader {
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #EFEFB6;
  z-index: 1;
  pointer-events: none;
}

.AppHeader > * {
  pointer-events: auto;
}

.AppLink {
  color: #61dafb;
}

@keyframes AppLogoSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
